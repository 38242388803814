import './App.css';
import React from "react";
import NotFound from './components/NotFound';
import PINCodes from './components/PINCodes';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Footer from "./components/Footer";
import NavBar from "./components/NavBar";

function App() {
  return (
    <React.Fragment>
    <NavBar />
    <Router>
      <Routes>
        <Route path="/PINCodes" element={<PINCodes />} />
        <Route path="/" element={<PINCodes replace to="/PINCodes" />} />
        <Route path="/*" component={NotFound} />
      </Routes>
    </Router>
    <Footer />
  </React.Fragment>
  );
}

export default App;
